@use '../utils/_variables' as *

.homeMenuResultprevious
    display: flex
    gap: 18px
    &__date
    &__name
        color: $textColorLight
        
.homeMenuResultnext
    display: flex
    gap: 18px
    &__date
        color: $textColorLight
    &__name
        color: $textColorLight
        