@use '../utils/_variables' as *
    
body
    margin: 0
    min-height: 100%
    font-family: 'Contrail One'
    background-image: url('../../img/background1.png')
    background-size: cover
    background-position: top right
    background-attachment: fixed
    @media (max-width: $mobileSize)
        background-color: $textColorLight
        background-image: none
        border: none

h2
    font-size: 30px
    color: $primaryColor
    @media (max-width: $mobileSize)
        text-align: center
h3
    font-size: 24px

p 
    font-size: 18px
    @media (max-width: $tabletSize)
        font-size: 16px

.link
    text-decoration: none
    color: black

.tabulation
    text-indent: 40px
    display: block
    margin-top: 10px
    @media (max-width: $mobileSize)
        display: initial
        text-indent: 0

.greenText
    color: $secondaryColor




.mainContainer
    width: 60%
    // min-width: 1020px 
    min-width: 950px 
    max-width: 1360px
    min-height: calc( 100vh - 90px )
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: start
    background-color: $textColorLight
    @media (max-width: $tabletSize)
        width: 100%
        min-width: initial


.globalArticle
    width: 88%
    // min-height: 100vh
    margin: 0 6% 60px 6%
    padding-top: 30px
    @media (max-width: $mobileSize)
        margin-bottom: 30px
    &__specialBorder
        width: 40%
        height: 200px
        position: relative
        top: 60px
        right: -60%
        z-index: 0
        margin-bottom: -200px
        border-top: 2px solid $primaryColor
        border-right: 2px solid $primaryColor
        @media (max-width: $mobileSize)
            display: none
