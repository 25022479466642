@use '../utils/_variables' as *

footer
    display: flex
    flex-direction: row
    justify-content: center
    align-items: start
    min-height: 190px
    padding-top: 15px
    background-color: $textColorDark
    @media (max-width: $mobileSize)
        flex-direction: column-reverse
        align-items: center

.footer__sectionLogo
    align-self: center
    padding-top: 0
    width: 25%
    height: 80%
    display: flex
    justify-content: center
    align-items: center
    gap: 10px
    flex-wrap: wrap
    @media (max-width: $mobileSize)
        width: 90%
        margin-bottom: 10px
    &__img
        height: 90px
    &__title
        font-size: 20px

.footer__sectionDescription
    width:25%
    height: 80%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: start
    border-left: 1px solid $primaryColor
    border-right: 1px solid $primaryColor
    @media (max-width: $mobileSize)
        width: 90%
        border: none
        border-top: 1px solid $primaryColor
        border-bottom: 1px solid $primaryColor
        margin: 10px 0
    &__title
        font-size: 20px
    &__text
        color: $textColorLight
        text-align: center
        padding: 0 2%
        width: 75%
        margin-top: -5px
    
.footer__sectionNav
    width: 25%
    height: 80%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: start
    padding-left: 3%
    @media (max-width: $mobileSize)
        width: 90%
        margin-bottom: 10px
    &__title
        font-size: 20px
    &__nav
        display: grid
        grid-template-columns: 1fr 1fr
        gap: 10px 5%
        @media (max-width: $mobileSize)
            display: flex
        &__link
            text-decoration: none
            color: $textColorLight