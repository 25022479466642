@use '../utils/_variables' as *

.homeMenuLink
    border-top: 1px solid black
    padding: 15px 0 0 10px
    transition: 0.2s ease-in-out
    &__arrow
        height: 18px
        margin: 0 10px -2px 0
    &:hover
        padding-left: 30px
        transition: 0.2s ease-in-out