@use '../utils/_variables' as *

.header
    width: 96%
    height: 90px
    display: flex
    justify-content: center
    align-items: center
    gap: 5%
    background-color: $primaryColor
    color: $textColorLight
    position: sticky
    top:0
    z-index: 5
    padding: 0 2%
    @media (max-width: $tabletSize)
        gap: 2.5%
    @media (max-width: $mobileSize)
        display: none
        flex-direction: column
        position: fixed
        width: 100%
        padding: 0
        height: 100vh
    &__title
        display: flex
        align-items: center
        &__img
            height: 90px
        &__text
            font-size: 20px
    &__nav
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        gap: 5vw
        @media (max-width: $tabletSize)
            gap: 2.5%
        @media (max-width: $mobileSize)
            flex-direction: column
        &__link
            color: $textColorLight
            font-size: 20px
            height: 100%
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            @media (max-width: $tabletSize)
                font-size: 18px
            &--border
                margin: 10px 0 -10px 0
                transition: 0.7s 0.2s ease-in-out
                width: 0
                height: 2px
                background-color: $textColorLight
        & .link:hover .header__nav__link--border
            transition: 0.2s ease-in-out
            width: 100%
            height: 2px



.submenu
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 15px
    position: relative
    top: 0
    height: 100%
    @media (max-width: $mobileSize)
        display: none
    &__title
        cursor: default
    &__links
        width: 100%
        padding: 0 55px
        display: none
        flex-direction: column
        gap: 10px
        overflow: visible
        background-color: $primaryColor
        position: absolute
        top:100%
        & .link
            padding: 14px
        
.mobileMenuLink
    display: none
    @media (max-width: $mobileSize)
        display: flex

.mobileMenuButton
    display: none
    @media (max-width: $mobileSize)
        display: initial
        width: 35px
        height: 35px
        position: absolute
        top: 30px 
        right: 30px


// .different:hover
//   border: none 
//   border-bottom: 5px solid #FFFFFF

// .different::after
//   content: ''
//   position: absolute
//   width: 0px
//   height: 5px
//   left: 50%
//   bottom:0
//   background-color: white
//   transition: all ease-in-out .2s

// .different:hover::after
//   width: 100%
//   left: 0
