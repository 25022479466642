@use '../utils/_variables' as *

.flagContainer
    position: fixed
    top: 100px
    right: 20px
    display: flex
    gap: 13px
    z-index: 6
    @media (max-width: $tabletSize)
        top: initial
        bottom: 20px
    &__flag
        height: 25px
        &:hover
            height: 35px
            cursor: pointer
            margin: -5px
        &--big
            height: 30px
            margin: -5px