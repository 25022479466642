@use '../utils/_variables' as *

.race
    display: flex
    justify-content: space-between
    width: 100%
    border-top: 1px solid $primaryColor
    padding: 10px 
    transition: 0.1s ease-in-out
    z-index: 3
    &:hover
        background-color: $primaryColor
        color: white
    &--1
        
    &--2
        background-color: $secondaryColor
    &__content
        margin: 0
            
