@use "../utils/_variables" as *


.articleContact
    margin: 20px 0 30px 0
    width: 98%
    gap: 3%
    &__content
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        @media (max-width: $mobileSize)
            flex-direction: column
    &__column
        width: 48.5%
        height: 300px
        display: flex
        flex-direction: column
        justify-content: center
        @media (max-width: $mobileSize)
            width: 100%
            align-items: center

.openStreetMap
    z-index: 0

.iframe
    border: 0
    width: 100%
    height: 2650px