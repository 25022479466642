@use '../utils/_variables' as *

.homeContainer
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    height: 100vh
    width: 75%
    margin: auto auto
    @media (max-width: $tabletSize)
        width: 100%
        height: 100%

.home__title
    display: flex
    align-items: center
    margin-bottom: 30px
    @media (max-width: $mobileSize)
        margin-bottom: 10px
    &__logo
        height: 160px
        @media (max-width: $tabletSize)
            height: 130px
        @media (max-width: $mobileSize)
            height: 100px
    &__text
        font-size: 26px
        color: $textColorLight
        @media (max-width: $mobileSize)
            font-size: 22px
            color: $textColorDark
.homeLogo
    height: 160px
    margin-bottom: 30px
    @media (max-width: $tabletSize)
        height: 130px
    @media (max-width: $mobileSize)
        height: 100px
        margin-top: 20px

.homeCard
    width: 80%
    background-color: $textColorLight
    display: flex
    @media (max-width: $tabletSize)
        width: 90%
    @media (max-width: $mobileSize)
        width: 100%
        flex-direction: column-reverse
        // margin-bottom: 50px
    &__title
        font-size: 26px
        margin: 0 0 40px 0
        color: $textColorDark
        @media (max-width: $tabletSize)
            font-size: 22px
    &__linkToAll
        align-self: center
        margin-top: 15px
        color: $textColorLight
        font-size: 18px
        border-bottom: 1px solid $textColorLight
        padding: 8px
        transition: 0.2s ease-in-out
        @media (max-width: $tabletSize)
            font-size: 16px
        &:hover
            border: 1px solid $textColorLight
            background-color: $textColorLight
            color: $primaryColor
            border-radius: 3px
            transition: 0.2s ease-in-out
        &__arrow
            height: 18px
            margin: 0 10px -2px 0
            color: white
            display: none
        &:hover .homeCard__linkToAll__arrow
            display: initial 
    &__left
        width: 50%
        padding: 18px 12px
        background-color: $secondaryColor
        display: flex
        flex-direction: column
        justify-content: space-between
        @media (max-width: $mobileSize)
            width: 100%
            padding: 15px 0 25px 0
        &__results
            margin-left: 3%
    &__right
        display: flex
        flex-direction: column
        width:50%
        @media (max-width: $mobileSize)
            width: 100%
        &__menu
            height: 40%
            padding: 18px 12px
            background-color: $textColorLight
        &__races
            height: 60%
            display: flex
            flex-direction: column
            padding: 18px 12px 12px 12px
            background-color: $primaryColor
            color: $textColorLight
            @media (max-width: $mobileSize)
                padding-bottom: 25px
            & h2
                color: $textColorLight
            &>.link
                color: $textColorLight
                &:hover
                    color: $primaryColor
            &__list
                margin-left: 3%
                &:hover
                    color: initial


